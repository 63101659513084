@tailwind base;
@tailwind components;
@tailwind utilities;

.borderFull {
  border-radius: 50%;
}

.tangerine {
  font-family: 'Tangerine', cursive;
}

.flexCenter {
  @apply flex items-center justify-center
}

.flexBetween {
  @apply flex items-center justify-between
}

.roundedB{
  border-radius: 50%;
}